import React from "react";
import Reveal from "react-reveal/Reveal";
import img from '../../assets/img/me.jpg';
import cv from '../../assets/pdf/cv.pdf'

const About = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <Reveal effect="fadeInLeft">
                  <div className="image">
                    <img src="/img/placeholders/1-1.jpg" alt="placeholder" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${img
                        })`,
                      }}
                    ></div>
                  </div>
                </Reveal>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <Reveal effect="fadeInLeft">
                  <div className="shane_tm_title">
                    <span>About Me</span>
                    <h3>Creative Independent Full-Stack based in Morocco.</h3>
                  </div>
                  <div className="text">
                    <p>
                      Hello My name is Marouane Hamza, and i'm a casablanca-based freelancer. 
                      If i'm not freelancing at some of casablanca's finest digital agencies, 
                      i'm working remotely on projects for clients all over the world.
                      <br/>
                      I have a diverse set skills, ranging from design, to React + HTML + Javascript, all the way to JAVA , 
                      Spring development and server administration and also CI/CD.
                    </p>
                  </div>
                  <div className="shane_tm_button">
                    <a href={cv} download>
                      Download CV
                    </a>
                  </div>
                </Reveal>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
