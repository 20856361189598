import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Reveal from "react-reveal/Reveal";
import cloudEco from '../../assets/img/CLOUDECO.png';
import sg from '../../assets/img/societegeneral.png';
import cdm from '../../assets/img/credit-du-maroc.png';
import cap from '../../assets/img/Capgemini.png';
import fly from '../../assets/img/fly.png';
import unibank from '../../assets/img/ebank.png';
//------------------------------------------------------------------
const PortfolioTwo = () => {
  return (
    <div className="shane_tm_section" id="portfolio">
      <SimpleReactLightbox>
        <div className="shane_tm_portfolio">
          <div className="container">
            <div className="positon-relative">
              <div className="shane_tm_title">
                <div className="title_flex">
                  <div className="left">
                    <span>Portfolio</span>
                    <h3>Total of my projects</h3>
                  </div>
                </div>
              </div>
              {/* End shane_tm_title */}
              <div className="portfolio_filter">
                <Tabs>
                  <TabList>
                    <Tab>All</Tab>
                    <Tab>Banks</Tab>
                    <Tab>Ecommerce</Tab>
                    <Tab>Other</Tab>
                  </TabList>
                  {/* End tablist */}
                  <div className="portfolio_list">
                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://cloudeco.fr/">
                                    <img
                                      src={cloudEco}
                                      alt="Design"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Cloud Eco</h3>
                                  <span>Back-End</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://societegenerale.africa/en">
                                    <img
                                      src={sg}
                                      alt="Branding"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Le groupe Société Générale</h3>
                                  <span>front & back</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="http://moneymanager.com2.s3-website.eu-central-1.amazonaws.com">
                                    <img
                                      src={unibank}
                                      alt="Photography"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Uni-Bank</h3>
                                  <span>Back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://www.creditdumaroc.ma">
                                    <img
                                      src={cdm}
                                      alt="Photography"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Crédit du Maroc</h3>
                                  <span>Back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://www.engie.com/en">
                                    <img
                                      src={cap}
                                      alt="Design"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Capgemini</h3>
                                  <span>Back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://www.fly74.fr/">
                                    <img
                                      src={fly}
                                      alt="Branding"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Fly</h3>
                                  <span>back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}
                        </ul>
                        {/* End portfolio list */}
                      </TabPanel>
                      {/* END ALL PORTFOLIO GALLERY */}

                      <TabPanel>
                        <ul className="gallery_zoom">
                        <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://societegenerale.africa/en">
                                    <img
                                      src={sg}
                                      alt="Branding"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Le groupe Société Générale</h3>
                                  <span>front & back</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="http://moneymanager.com2.s3-website.eu-central-1.amazonaws.com">
                                    <img
                                      src={unibank}
                                      alt="Photography"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Uni-Bank</h3>
                                  <span>Back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://www.creditdumaroc.ma/">
                                    <img
                                      src={cdm}
                                      alt="Photography"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Crédit du Maroc</h3>
                                  <span>Back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}
                        </ul>
                        {/* End portfolio list */}
                      </TabPanel>

                      <TabPanel>
                        <ul className="gallery_zoom">
                        <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://cloudeco.fr/">
                                    <img
                                      src={cloudEco}
                                      alt="Design"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Cloud Eco</h3>
                                  <span>Back-End</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://www.fly74.fr/">
                                    <img
                                      src={fly}
                                      alt="Branding"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Fly</h3>
                                  <span>back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                        </ul>
                        {/* End single image block */}
                      </TabPanel>

                      <TabPanel>
                        <ul className="gallery_zoom">
                        <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="https://www.engie.com/en">
                                    <img
                                      src={cap}
                                      alt="Design"
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Capgemini</h3>
                                  <span>Back & Front</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                        </ul>
                        {/* End single image block */}
                      </TabPanel>
                    {/* End tabpanel */}
                  </div>
                  {/* End list wrapper */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </div>
  );
};

export default PortfolioTwo;
